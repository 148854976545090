import React, { FC } from 'react';
import ArticleSubMenuItem from 'gatsby-theme-husky/src/components/ArticleSubMenuItem';
import TagsList from 'gatsby-theme-husky/src/components/TagsList';
import Button from 'gatsby-theme-husky/src/common/Button';
import { stringUtils } from 'gatsby-theme-husky/src/utils';
import { ArticleSubMenuProps } from 'gatsby-theme-husky/src/components/ArticleSubMenu/model';
import 'gatsby-theme-husky/src/components/ArticleSubMenu/ArticleSubMenu.scss';

const ArticleSubMenu: FC<ArticleSubMenuProps> = ({
  content,
  bodySubNavigation,
  tags,
  articleSubMenuTopics,
}) => {
  const articleSection = 'Article section';
  const { properties } = bodySubNavigation[0];

  return (
    <div className="article-submenu">
      <Button
        className="article-submenu__button"
        ariaLabel=""
        btnColorVariant="outline-dark"
        link={properties.subnavigationMenuButtonULR?.[0]?.url || '/'}
      >
        {properties.subnavigationMenuButton}
      </Button>
      <h3 className="article-submenu__header">{properties.subnavigationMenuTitle}</h3>
      <ul className="article-submenu__list">
        {content
          .filter(({ structure }) => structure === articleSection)
          .map(({ properties: { articleSectionTitle } }) =>
            articleSectionTitle ? (
              <ArticleSubMenuItem
                key={articleSectionTitle}
                name={stringUtils.titleCapitalized(articleSectionTitle)}
                linkToSection={articleSectionTitle}
              />
            ) : null
          )}
      </ul>
      <div className="article-submenu__topics">{articleSubMenuTopics}</div>
      <TagsList dark tags={tags} />
    </div>
  );
};

export default ArticleSubMenu;
